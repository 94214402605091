<template>
    <div>
        <el-row>
            <el-col :span="8">
                <el-input v-model="serialNumber" @change="getList(1)">
                    <template slot="prepend">序列号或者产品类型</template>
                    <el-button slot="append" icon="el-icon-search" @click="getList(1)"></el-button>
                </el-input>
            </el-col>
            <el-col :span="16" class="operate">
                <el-button-group>
                    <el-button type="primary" @click="$refs.addDialog.add()">增加</el-button>
                    <el-button type="success" @click="print(4)">打印内包装(横)</el-button>
                    <el-button type="success" @click="print(0)">打印内包装(竖)</el-button>
                    <el-button type="success" @click="print(1)">打印外包装</el-button>
                    <el-button type="success" @click="print(2)">打印合格证</el-button>
                    <el-button type="success" @click="print(3)">打印说明书</el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-table style="margin-top: 10px;" ref="elTable" :data="tableData" v-loading="tableLoading" @row-dblclick="row => $refs.editDialog.edit(row)" border>
            <el-table-column type='selection' align="center"></el-table-column>
            <el-table-column prop="name" label="产品名称"></el-table-column>
            <el-table-column prop="serialNumber" label="序列号"></el-table-column>
            <el-table-column prop="dateOfProduction" label="出厂日期" width="155"></el-table-column>
            <el-table-column prop="deviceType" label="产品类型"></el-table-column>
            <el-table-column prop="pwd" label="密钥" align="right" width="100"></el-table-column>
            <el-table-column prop="expiresDate" label="过期日期" width="155"></el-table-column>
            <el-table-column label="操作" width="100" align="center">
                <template #default="scope">
                    <el-button-group>
                        <el-button type="danger" size="mini" @click="del(scope.row)">删除</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="getList" background :total="total" :page-size="pageSize" :current-page.sync="currentPage"></el-pagination>
        <add-dialog ref="addDialog"></add-dialog>
        <edit-dialog ref="editDialog"></edit-dialog>
        <q-r-code-dialog ref="qrCodeDialog"></q-r-code-dialog>
    </div>
</template>

<script>
import AddDialog from "../Device/AddDialog.vue";
import EditDialog from "../Device/EditDialog.vue";
import QRCodeDialog from "../Device/QRCodeDialog.vue";
export default {
    components: { AddDialog, EditDialog, QRCodeDialog },
    data() {
        return {
            pageSize: 10,
            currentPage: 1,
            total: 0,
            serialNumber: "",
            tableLoading: false,
            tableData: null,
        };
    },
    methods: {
        getList(cp) {
            let that = this;
            if (cp) that.currentPage = cp;
            that.tableLoading = true;
            that.axios
                .post("Base_Device/GetList", {
                    serialNumber: that.serialNumber,
                    pageSize: that.pageSize,
                    currentPage: that.currentPage,
                    total: that.total,
                })
                .then(function (response) {
                    that.tableLoading = false;
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                });
        },
        del(row) {
            let that = this;
            that
                .$confirm("确定要删除吗？")
                .then(function () {
                    that.axios.post("Base_Device/DelModel", { id: row.id }).then(function () {
                        that.getList();
                    });
                })
                .catch(function () { });
        },
        showQRCode(row) {
            let that = this;
            that.$refs.qrCodeDialog.show(row);
        },
        print(type) {
            let that = this;
            if (that.$refs.elTable.selection.length == 0) {
                that.$alert("请选中行");
            } else {
                let ids = "";
                that.$refs.elTable.selection.forEach((element) => {
                    ids += element.id + ",";
                });
                window.open(process.env.VUE_APP_TurnUrl + "Device/Print" + type + "?ids=" + ids);
            }
        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style scoped>
.operate {
    padding-left: 4px;
}
</style>